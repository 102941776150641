body {
    font-family: Arial, sans-serif;
    background-color: #ffffff;
    color: #333333;
    margin: 0;
    padding: 0;
}

.container {
    max-width: 1200px;
    margin: 0 auto;
    padding: 20px;
}

/* Hero Section with Animation */
.hero {
    background-color: #f7bc0e;
    padding: 80px 20px;
    color: #000000;
    text-align: center;
    overflow: hidden;
    position: relative;
}

.hero h1 {
    font-size: 2.5em;
    margin-bottom: 20px;
    animation: fadeInSlideUp 1.5s ease-out;
}

.hero p {
    font-size: 1.2em;
    margin-bottom: 30px;
    animation: fadeIn 2s ease-out 0.5s;
    animation-fill-mode: both;
}

.cta-buttons a {
    text-decoration: none;
    padding: 12px 25px;
    border-radius: 5px;
    font-size: 0.9em;
    margin: 10px;
    display: inline-block;
    animation: bounceIn 1.5s ease-out;
    animation-fill-mode: both;
    transition: transform 0.3s;
}

.cta-buttons a:nth-child(1) {
    animation-delay: 1s;
}

.cta-buttons a:nth-child(2) {
    animation-delay: 1.5s;
}

.primary-cta {
    background-color: #000000;
    color: #ffffff;
}

.secondary-cta {
    background-color: #333;
    color: #ffffff;
}

.cta-buttons a:hover {
    transform: scale(1.1);
}

.section {
    background-color: #fff;
    padding: 40px 20px;
    margin-bottom: 20px;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    text-align: left;
}

.section h2 {
    color: #ffcc00;
    margin-bottom: 20px;
}

.section p {
    font-size: 1em;
    line-height: 1.6;
}

.activity-options ul {
    list-style-type: disc;
    padding-left: 20px;
}

.activity-options li {
    margin: 10px 0;
    font-size: 1.1em;
}

.footer {
    text-align: center;
    padding: 20px;
    background-color: #333;
    color: #fff;
}

.footer a {
    color: #ffcc00;
    text-decoration: none;
    margin: 0 10px;
}

/* Keyframes for Animations */
@keyframes fadeInSlideUp {
    0% {
        opacity: 0;
        transform: translateY(20px);
    }
    100% {
        opacity: 1;
        transform: translateY(0);
    }
}

@keyframes fadeIn {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

@keyframes bounceIn {
    0% {
        transform: scale(0.8);
        opacity: 0;
    }
    60% {
        transform: scale(1.05);
        opacity: 1;
    }
    100% {
        transform: scale(1);
    }
}

.facilities-slider {
    overflow: hidden;
    position: relative;
    background-color: #fff;
    padding: 40px 20px;
    margin-bottom: 20px;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    text-align: center;
}

.slider-container {
    display: flex;
    gap: 20px;
    animation: slide-continuous 30s linear infinite;
    will-change: transform;
    width: calc(200% + 20px);
}

.slide {
    width: auto;
    height: auto;
    background-color: #f7bc0e;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    font-size: 1em;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
}

@keyframes slide-continuous {
    0% {
        transform: translateX(0);
    }
    100% {
        transform: translateX(-50%);
    }
}